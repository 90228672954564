import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { method } from 'lodash'
import {
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT
} from '../../static/svgtext'

export const ContainerSVGT = bookmarkSVGT
export const ContainerChoice = "Circle"
export const MainFont = 'Bahnschrift'
export const SubFont = 'Poppins'

export const HomeColor = "#00A0A0" //48CFAD
export const BeliefsColor = "#00A0A0" //38BDBB
export const EventsColor = "#2F937A"
export const LiveColor = "#48CFAD"
export const SermonsColor = "#66D0CD"
export const YouthColor = "#3AB07B"
export const ContactColor = "#69B3CD"
export const ThirdPage = "Video (YouTube)"
export const FourthPage = "Sermons"
export const YouthName = "Kids"